import React from 'react';
import {ProductLaunch2PFlow} from "./ProductLaunch2PFlow";
import {ProductUpdate2PFlow} from "./ProductUpdate2PFlow";
import {UpfrontPaymentFlow} from "./UpfrontPaymentFlow";
import {TranslationModerationWorkflow} from "./TranslationModerationWorkflow";
import {ColumnLayout, Container, Header} from "@amzn/awsui-components-react";
import {NodeDataForm} from "./NodeDataForm";
import "./InteractiveWorkflow.css"
import { AMMPSellerProfileReviewWorkflow } from './AMMPSellerProfileReviewWorkflow';

export class InteractiveWorkflow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedNodeId: '',
            widgetListPerNode: {},
        };
        this.setSelectedNodeId = this.setSelectedNodeId.bind(this);
    }

    setSelectedNodeId(nodeId) {
        this.setState({selectedNodeId: nodeId});
    }

    getGraphType(workflowType) {
        switch (workflowType) {
            case "AMMP_Seller_Profile_Review_Workflow":
              return <AMMPSellerProfileReviewWorkflow details={this.props.details} setSelectedNodeId={this.setSelectedNodeId}/>;
            case "Product_Publish_Workflow":
            case "Product_Publish_Workflow_zhanylTest": // TODO (zhanyl): remove after demo testing
                return <ProductLaunch2PFlow details={this.props.details} setSelectedNodeId={this.setSelectedNodeId}/>;
            case "Product_Update_Workflow":
                return <ProductUpdate2PFlow details={this.props.details} setSelectedNodeId={this.setSelectedNodeId}/>;
            case "Upfront_Payment_Workflow":
                return <UpfrontPaymentFlow details={this.props.details} setSelectedNodeId={this.setSelectedNodeId}/>;
            case "Translation_Moderation_Workflow_SaaSPDPProductV1":
            case "Translation_Moderation_Workflow_SaaSPDPOfferV1":
            case "Translation_Moderation_Workflow_ServerPDPDetailOfferV1":
            case "Translation_Moderation_Workflow_ServerPDPDetailProductV2":
            case "Translation_Moderation_Workflow_discovery-listing-summary":
            case "Translation_Moderation_Workflow_acceptor-offer-details":
            case "Translation_Moderation_Workflow_MPEListingDetailProductData":
            case "Translation_Moderation_Workflow_discovery-listing-detail-overview":
            case "Translation_Moderation_Workflow_discovery-listing-detail-support":
            case "Translation_Moderation_Workflow_discovery-server-fulfillment-option-summary":
                return <TranslationModerationWorkflow details={this.props.details} setSelectedNodeId={this.setSelectedNodeId}/>;
            default:
                return <div />;
        }
    }

    render() {
        const graph = this.getGraphType(this.props.details.workflowType);
        return (
            <Container header={<Header variant="h2">Interactive Workflow</Header>}>
                <ColumnLayout columns={2}>
                    <Container>
                        {graph}
                        <div className="bpm-workflow-legend" ng-show="showLegend">
                            <ul>
                                <li>
                                    <div className="bpm-workflow-legend-active" />
                                    <small> Active </small>
                                </li>
                                <li>
                                    <div className="bpm-workflow-legend-completed" />
                                    <small> Completed </small>
                                </li>
                                <li>
                                    <div className="bpm-workflow-legend-failed" />
                                    <small> Failed </small>
                                </li>
                                <li>
                                    <div className="bpm-workflow-legend-pending" />
                                    <small> Pending </small>
                                </li>
                                <li>
                                    <div className="bpm-workflow-legend-rollback" />
                                    <small> Caution </small>
                                </li>
                            </ul>
                        </div>
                    </Container>
                    <Container header={<Header variant='h3'>Details</Header>} className="bpm-table-scroll">
                        <NodeDataForm nodeList={this.props.details.nodeList} selectedNodeId={this.state.selectedNodeId}
                                      workflowId={this.props.details.id} workflowType={this.props.details.workflowType}
                                      setAlertMsg={this.props.setAlertMsg}  setSuccessMsg={this.props.setSuccessMsg} />
                    </Container>
                </ColumnLayout>
            </Container>
        );
    }

}
